@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css?family=Public+Sans&display=swap");
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP&display=swap");

@import "./styles/mixin/Global.scss";
@import "./styles/Animation.scss";

html {
  overflow-x: hidden;
}

body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  //   Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
  //   sans-serif;
  font-family: "Public Sans", "Noto Sans JP", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: #000000;
  color: #e8efe9;
  overflow-x: hidden;
  font-size: calc(12px + .35vw);
  line-height: 1.6;
  // line-height: calc(12px + 1.05vw); /* Responsive Vertical Rhythm */
}

h1 {
  font-size: 1.912em;
  line-height: calc(18px + 1.8vw); /* Responsive Vertical Rhythm */
}

h2 {
  font-size: 1.616em;
  // line-height: calc(18px + 1vw); /* Responsive Vertical Rhythm */
}

h3 {
  font-size: 1.471em;
  line-height: calc(18px + 0.7vw); /* Responsive Vertical Rhythm */
}

h4 { font-size: 1.3em; }
h5 { font-size: 1.243em; }
h6 { font-size: 1.132em; }

h4, h5, h6 { 
  line-height: calc(18px + .2vw); /* Responsive Vertical Rhythm */
}

h1, h2, h3, h4, h5, h6 {
  margin: calc(12px + 1.05vw) 0; /* Responsive margins */
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}

.center {
  text-align: center;
}

.button {
  color: #ffffff;
  &:hover {
    opacity: 0.7;
  }
}

.buttonWrapper {
  a {
    @extend .button;
  }
}

.breakText {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}