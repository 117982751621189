#gradient-vertical {
  --color-stop-1: #00c3ff;
  --color-stop-2: #77e190;
  --color-stop-3: #ffff1c;
}

#gradient-vertical-sm {
  --color-stop-1: #833ab4;
  --color-stop-2: #fd1d1d;
  --color-stop-3: #fcb045;
}

#svg-logo {
  path {
    // fill: url(#gradient-vertical) gray;
    fill: url(#logo-gradient) gray;
  }
}

#svg-logo-sm {
  path {
    //fill: url(#gradient-vertical-sm) gray;
    fill: url(#logo-gradient-sm) gray;
  }
}