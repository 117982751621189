@import "styles/Animation.scss";
@import "styles/mixin/Global.scss";

#workBG {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  z-index: 0;
  background: rgb(0, 128, 0);
  height: 100%;
  width: 100%;

  .workDetail {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
    height: 200px;
  }
}

#modalInside {
  background-color: rgba(0, 0, 0, 0.8);
  padding: 0 40px 0;
  width: 100%;
  position: absolute;
  max-height: 100%;
  overflow-y: auto;
}

img {
  width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

#workPrevious {
  text-align: left;  
}

#workNext {
  text-align: right;
}

.pagination {
  margin: 0 0 60px;
  padding: 0;
  display: inline-block;
  width: 100%;

  @include clearit;

  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

.modalInsideShow {
  opacity: 1;
  animation: fadeIn 1s;
}

.modalInsideHide {
  opacity: 0;
  animation: fadeOut 0.5s;
}