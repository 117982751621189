@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css?family=Public+Sans&display=swap);
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP&display=swap);
@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }
@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

html {
  overflow-x: hidden; }

body {
  margin: 0;
  font-family: "Public Sans", "Noto Sans JP", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000000;
  color: #e8efe9;
  overflow-x: hidden;
  font-size: calc(12px + .35vw);
  line-height: 1.6; }

h1 {
  font-size: 1.912em;
  line-height: calc(18px + 1.8vw);
  /* Responsive Vertical Rhythm */ }

h2 {
  font-size: 1.616em; }

h3 {
  font-size: 1.471em;
  line-height: calc(18px + 0.7vw);
  /* Responsive Vertical Rhythm */ }

h4 {
  font-size: 1.3em; }

h5 {
  font-size: 1.243em; }

h6 {
  font-size: 1.132em; }

h4, h5, h6 {
  line-height: calc(18px + .2vw);
  /* Responsive Vertical Rhythm */ }

h1, h2, h3, h4, h5, h6 {
  margin: calc(12px + 1.05vw) 0;
  /* Responsive margins */ }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace; }

.center {
  text-align: center; }

.button, .buttonWrapper a {
  color: #ffffff; }
  .button:hover, .buttonWrapper a:hover {
    opacity: 0.7; }

.breakText {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto; }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

#workBG {
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  z-index: 0;
  background: green;
  height: 100%;
  width: 100%; }
  #workBG .workDetail {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 200px;
    height: 200px; }

#modalInside {
  background-color: rgba(0, 0, 0, 0.8);
  padding: 0 40px 0;
  width: 100%;
  position: absolute;
  max-height: 100%;
  overflow-y: auto; }

img {
  width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  display: block; }

#workPrevious {
  text-align: left; }

#workNext {
  text-align: right; }

.pagination {
  margin: 0 0 60px;
  padding: 0;
  display: inline-block;
  width: 100%;
  zoom: 1; }
  .pagination:before, .pagination:after {
    content: "";
    display: table; }
  .pagination:after {
    clear: both; }
  .pagination li {
    margin: 0;
    padding: 0;
    list-style: none; }

.modalInsideShow {
  opacity: 1;
  -webkit-animation: fadeIn 1s;
          animation: fadeIn 1s; }

.modalInsideHide {
  opacity: 0;
  -webkit-animation: fadeOut 0.5s;
          animation: fadeOut 0.5s; }

.player-wrapper {
  position: relative;
  padding-top: 56.25%; }

.react-player {
  position: absolute;
  top: 0;
  left: 0; }

#gradient-vertical {
  --color-stop-1: #00c3ff;
  --color-stop-2: #77e190;
  --color-stop-3: #ffff1c; }

#gradient-vertical-sm {
  --color-stop-1: #833ab4;
  --color-stop-2: #fd1d1d;
  --color-stop-3: #fcb045; }

#svg-logo path {
  fill: url(#logo-gradient) gray; }

#svg-logo-sm path {
  fill: url(#logo-gradient-sm) gray; }

canvas {
  width: 100%;
  height: 100%;
  display: block;
  overflow: hidden; }

.App {
  text-align: center; }

.App-logo {
  height: 40vmin;
  pointer-events: none; }

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear; } }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }

.App-link {
  color: #61dafb; }

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

